import React, { useContext } from 'react';
import './index.scss';
import { UserContext } from '../../contexts/User/UserContext';
import { getJwt, getJwtExpirationDate } from '../../utils/jwt';
import { useTranslation } from 'react-i18next';

interface User {
  addressId?: string;
  displayName?: string;
  firstName?: string;
  id?: string;
  login?: string;
  freeOne?: string;
  lastName?: string;
  mail?: string;
}

type ComponentProps = { id: string; };

const UserInfo: React.FC<ComponentProps> = ({ id }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common"]);
  return (
    <aside className="UserInfo" id={id}>
      <h1 className="UserAccount__Title">{t("User Account")}</h1>
      <div className="UserInfo__ImageWrap">
        <img className="UserInfo__Image" src="/generic-user.jpg"></img>
      </div>
      <div className="UserInfo__Info">
        <h2 className="UserInfo__DisplayName">{user?.displayName || t("Guest user")}</h2>
        {(user && (
          <ul className="UserInfo__Details">
            <li className="UserInfo__Details--Item">{t("Email")}: {user.mail}</li>
            <li className="UserInfo__Details--Item">{t("Login")}: {user.login}</li>
            <li className="UserInfo__Details--Item">{t("Member since")}: {(user as User).freeOne}</li>
            <li className="UserInfo__Details--Item">{t("Session expires at")}: {getJwtExpirationDate(getJwt())?.toLocaleTimeString()}</li>
          </ul>
        )) || null}
      </div>
    </aside>
  );
};

export default UserInfo;
