import { RemoteServiceId } from "../api/implementations/types";

export function base64UrlDecode(input: string) {
  input = input.replace(/-/g, '+').replace(/_/g, '/');
  const padLength = (4 - input.length % 4) % 4;
  input += '='.repeat(padLength);
  const base64 = window.atob(input);
  try {
    return decodeURIComponent(escape(base64));
  } catch (e) {
    return base64;
  }
}

type JwtHeader = {
  typ: "JWT",
  alg: "HS256"
}

export interface User {
  adressId?: string;
  displayName?: string;
  firstName?: string;
  id?: string;
  login?: string;
  lastName?: string;
  mail?: string;
}

export type JwtPayloadUser = {
  userID: string;
  login: string;
  isAdmin: boolean;
  serviceId: RemoteServiceId;
  representedServiceId: RemoteServiceId;
  sessionStartTime: number; // 1713269808,
  remotePHPSESSID: string;
  client: string;
}

export type JwtPayload = {
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  sub: string;
  user: JwtPayloadUser;
}

type DecodedJwt = { payload: JwtPayload, header: JwtHeader; }

export function decodeJwt(token: string): DecodedJwt|null {
  const parts = token.split('.');
  if (parts.length !== 3) {
    return null;
  }
  const header = JSON.parse(base64UrlDecode(parts[0]));
  const payload = JSON.parse(base64UrlDecode(parts[1]));
  return { header, payload };
}

export function isNullOrExpired(jwtToken: string|null): boolean|null {
  const dateOrNull = getJwtExpirationDate(jwtToken);
  return dateOrNull ? new Date() > dateOrNull : true;
}

export function getJwt() {
  const jwtToken = localStorage.getItem('jwtToken')
  return jwtToken;
}

export function getJwtExpirationDate(jwtToken: string|null): Date|null {
  if (jwtToken === null) return null;
  const jwt = decodeJwt(jwtToken)?.payload;
  if (!jwt) {
      return null;
  }
  return new Date(jwt["exp"] * 1000);
}

export function setJwt(jwtToken: string) {
  localStorage.setItem('jwtToken', jwtToken);
}

export function hasActiveJwt() {
  const jwtToken = getJwt();
  return !isNullOrExpired(jwtToken);
}

export function deleteIfExpiredJwt() {
  const jwtToken = getJwt();
  isNullOrExpired(jwtToken) && localStorage.removeItem('jwtToken');
}