import { useEffect } from "react";
import { l } from "../utils/log";
import { TabIndex } from "../types/accessibility";
import { useFocusNeeded } from "../contexts/FocusNeeded/FocusNeededProvider";

const log = l("useFocusOn");

const useFocusOn = (ref: React.RefObject<HTMLElement>, requestFocus: boolean) => {
  const { needFocusOnContent, setNeedFocusOnContent } = useFocusNeeded();

  useEffect(() => {
    if (!requestFocus) return;
    setNeedFocusOnContent(true);
  }, [requestFocus]);

  useEffect(() => {
    const doIt = needFocusOnContent;// && needFocusOnContent;
    log("useEffect");
    if (!ref.current) {
      log("no current ref");
      return;
    }
    if (document.activeElement === ref.current) {
      log("already focused");
      if (doIt) {
        log("asked to focus, but not needed, reset to false");
        setNeedFocusOnContent(false)
      }
      return ;
    }

    log("doIt ?", doIt);
    log("needFocusOnContent", needFocusOnContent);
    if (doIt) {
      log("focus allowed");
      const id = setTimeout(() => {
        log("CALLING - inside timeout");
        if (ref.current?.getAttribute("tabindex") !== String(TabIndex.notReachableButProgramaticallyFocusable)) {
          ref.current?.setAttribute("tabindex", String(TabIndex.notReachableButProgramaticallyFocusable));
        }
        ref.current?.focus();
        log("focusing probably?", Boolean(ref.current?.focus));
        log("resetting to false");
        setNeedFocusOnContent(false);
      }, 300);
      return () => {
        log("clearing - unmounting");
        clearTimeout(id);
      }
    }
  }, [ref, needFocusOnContent, setNeedFocusOnContent]);
};

export default useFocusOn;
