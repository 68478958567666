import React, { HtmlHTMLAttributes } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { toIntOr } from "../../utils/search";
import "./index.scss";
import { l } from "../../utils/log";
import { PaginationButton } from "./PaginationButton";
import { PaginationInfo } from "./PaginationInfo";
import { usePageNavigation } from "../../hooks/usePageNavigation";
import useBookSearch from "../../hooks/useBookSearch";

const log = l("Pagination");

const countPerPage = toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10);
type ComponentProps = {
  withinToolbar?: boolean;
} & HtmlHTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<ComponentProps> = ({ withinToolbar = false, ...rest }) => {
  const {
    totalCount,
    receivedCount,
    currentBooksValuesParam,
  } = useBookSearch();
  const { movePage } = usePageNavigation();
  const { t } = useTranslation(["common"]);
  const { page } = currentBooksValuesParam;
  log("Page", page);
  const humanReadablePage = page + 1;
  const displayedPreviouslyCount = page * (countPerPage || 0);
  const displayedSoFarCount = displayedPreviouslyCount + (receivedCount || 0);
  const isFirstPage = page === 0;
  const isLastPage = displayedSoFarCount >= totalCount;
  const totalNumberOfPages = Math.ceil(totalCount / countPerPage);

  return (
    <nav className="Pagination__Container" {...rest}>
      <PaginationButton
        onClick={() => movePage("backward")}
        disabled={isFirstPage}
        label={t("Previous page")}
        withinToolbar={withinToolbar}
      >
        <MdOutlineNavigateBefore aria-hidden="true" size={25} />
      </PaginationButton>
      <PaginationInfo
        currentPage={humanReadablePage}
        totalPages={totalNumberOfPages}
        t={t}
      />
      <PaginationButton
        onClick={() => movePage("forward")}
        disabled={isLastPage}
        label={t("Next page")}
        withinToolbar={withinToolbar}
      >
        <MdOutlineNavigateNext aria-hidden="true" size={25} />
      </PaginationButton>
    </nav>
  );
};

export default Pagination;
