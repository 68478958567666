import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchRouteFromParams, toIntOr } from '../utils/search';
import useBookSearch from './useBookSearch';

const countPerPage = toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10);

export const usePageNavigation = () => {
  const navigate = useNavigate();
  const {
    totalCount,
    currentBooksValuesParam,
  } = useBookSearch();

  const movePage = useCallback((
    direction: "backward" | "forward",
  ) => {
    const { page } = currentBooksValuesParam;
    if (direction === "backward" && page === 0) return;
    const totalNumberOfPages = Math.ceil(totalCount / countPerPage);
    const plusMinusOneOrNull = (direction === "forward" && page < totalNumberOfPages)
      ? +1
      : ((direction === "backward" && page > 0)
        ? -1
        : null
    )
    if (plusMinusOneOrNull === null) return;
    navigate(getSearchRouteFromParams({
      ...currentBooksValuesParam,
      page: page + plusMinusOneOrNull,
    }), { state: { needsToAskForFocus: true }});
  }, [navigate, currentBooksValuesParam]);

  return {
    movePage,
  };
};