import React, { useRef, KeyboardEvent } from 'react';
import './index.scss';

interface TabListProps {
  activeIndex: number;
  onTabChange: (index: number) => void;
  children: React.ReactElement[];
  label: string;
}

export function TabList({ activeIndex, onTabChange, children, label }: TabListProps) {
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const tabCount = React.Children.count(children);
    let newIndex = activeIndex;

    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        newIndex = (activeIndex + 1) % tabCount;
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        newIndex = (activeIndex - 1 + tabCount) % tabCount;
        break;
      case 'Home':
        newIndex = 0;
        break;
      case 'End':
        newIndex = tabCount - 1;
        break;
      default:
        return;
    }

    event.preventDefault();
    onTabChange(newIndex);
    tabRefs.current[newIndex]?.focus();
  };

  return (
    <div
      role="tablist"
      aria-label={label}
      className="Tab__Container"
      onKeyDown={handleKeyDown}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ref: (el: HTMLButtonElement) => (tabRefs.current[index] = el),
          isActive: index === activeIndex,
          onClick: () => onTabChange(index),
        })
      )}
    </div>
  );
}