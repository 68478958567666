import React, { useContext, useEffect, useRef } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useQuickLinks } from '../../contexts/QuickLinks/QuickLinksProvider';
import { LinkableElementId, LinksDisplayRegion } from '../../contexts/QuickLinks/QuickLinksContext';
import SearchProvider from '../../contexts/Search/SearchProvider';
import { SearchQueryProvider } from '../../contexts/SearchQuery/SearchQueryProvider';
import ErrorModal from "../../components/ErrorModal";
import ErrorContext from '../../contexts/Error/ErrorContext';
import { FocusNeededProvider } from '../../contexts/FocusNeeded/FocusNeededProvider';

type ComponentProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children?: React.ReactNode;
  header: React.ReactNode;
  main: React.ReactNode;
  footer: React.ReactNode;
};

export default function Page({
  children = null,
  header,
  main,
  footer,
  ...rest
}: ComponentProps) {
  const { t } = useTranslation(['common']);
  const { links, addLink, removeLink } = useQuickLinks(LinksDisplayRegion.pageComponent);
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const { shownErrorStack } = useContext(ErrorContext);
  const [shownError] = shownErrorStack;

  useEffect(() => {
    const defaultLinks = [
      {
        id: LinkableElementId.pageMain,
        href: `#${LinkableElementId.pageMain}`,
        label: 'Go to main content',
        priority: 10,
      },
      {
        id: LinkableElementId.pageHeader,
        href: `#${LinkableElementId.pageHeader}`,
        label: 'Go to header',
        priority: 20,
      },
      {
        id: LinkableElementId.pageFooter,
        href: `#${LinkableElementId.pageFooter}`,
        label: 'Go to footer',
        priority: 30,
      },
    ];

    defaultLinks.forEach(link => addLink({ link, namespace: LinksDisplayRegion.pageComponent }));

    return () => {
      defaultLinks.forEach((link) => removeLink({ id: link.id, namespace: LinksDisplayRegion.pageComponent }));
    };
  }, [addLink, removeLink]);

  // Map to store original values of focusable elements that are modified
  const focusableElementsOriginalState = useRef(new Map<HTMLElement, { tabIndex: string | null; ariaDisabled: string | null; pointerEvents: string }>());

  useEffect(() => {
    if (pageContainerRef.current && shownError) {
      const focusableElements = pageContainerRef.current.querySelectorAll(
        'a, button, input, textarea, select, [tabindex]'
      ) as NodeListOf<HTMLElement>;

      // Disable elements only when the error is shown
      focusableElements.forEach((el) => {
        if (!focusableElementsOriginalState.current.has(el)) {
          // Store original values if they haven't been stored yet
          focusableElementsOriginalState.current.set(el, {
            tabIndex: el.getAttribute('tabindex'),
            ariaDisabled: el.getAttribute('aria-disabled'),
            pointerEvents: el.style.pointerEvents || '',
          });

          // Disable the elements
          el.setAttribute('tabindex', '-1');
          el.setAttribute('aria-disabled', 'true');
          el.style.pointerEvents = 'none';
        }
      });
    }

    if (!shownError && focusableElementsOriginalState.current.size > 0) {
      // If shownError becomes false, restore the original values
      focusableElementsOriginalState.current.forEach((originalState, el) => {
        // Restore original tabindex
        if (originalState.tabIndex !== null) {
          el.setAttribute('tabindex', originalState.tabIndex);
        } else {
          el.removeAttribute('tabindex');
        }

        // Restore original aria-disabled
        if (originalState.ariaDisabled !== null) {
          el.setAttribute('aria-disabled', originalState.ariaDisabled);
        } else {
          el.removeAttribute('aria-disabled');
        }

        // Restore original pointer-events
        el.style.pointerEvents = originalState.pointerEvents;
      });

      // Clear the map once restoration is done
      focusableElementsOriginalState.current.clear();
    }
  }, [shownError]);

  return (
    <SearchProvider>
      <SearchQueryProvider>
        <FocusNeededProvider>
          <div className="Page">
            <div
              ref={pageContainerRef}
              className="Page__Container"
              {...rest}
            >
              {links.map((link) => (
                <a key={link.id} href={link.href} className="visually-hidden">
                  {t(link.label)}
                </a>
              ))}
              <header role="banner" id={LinkableElementId.pageHeader}>
                {header}
              </header>
              <main className="Page__Main" role="main" id={LinkableElementId.pageMain}>
                {main}
              </main>
              <footer role="contentinfo" id={LinkableElementId.pageFooter}>
                {footer}
              </footer>
            </div>
            <ErrorModal />
            {children}
          </div>
        </FocusNeededProvider>
      </SearchQueryProvider>
    </SearchProvider>
  );
}
