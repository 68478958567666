import { createContext } from 'react';

interface FocusNeededContextType {
  needFocusOnContent: boolean;
  setNeedFocusOnContent: (value: boolean) => void;
}

export const FocusNeededContext = createContext<FocusNeededContextType>({
  needFocusOnContent: false,
  setNeedFocusOnContent: () => {},
});