import React, { useEffect, useMemo, useRef } from "react";
import BookCard from "../BookCard";
import { BookReducedInfo } from "../../types/book";
import Loading from "../Loading";
import './index.scss';
import { RemoteServiceId } from "../../api/implementations/types";
import { TabIndex } from "../../types/accessibility";
import { useNotifications } from "../../contexts/Notification/NotificationProvider";
import { NotificationNamespace, NotificationPriority } from "../../contexts/Notification/NotificationContext";
import { renderMaybeSearchTerm } from "../../utils/renderQuotes";
import { l } from "../../utils/log";
import { RequestStatus } from "../../api/constants/apiStatus";
import { LinkableElementId, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import { useTranslation } from "react-i18next";
import { notification_books_error, notification_books_loaded, notification_books_loading } from "../../utils/notifications";
import useFocusOn from "../../hooks/useFocusOn";
import { useLocation } from "react-router-dom";

type ComponentProps = {
  containerId: string;
  title: React.ReactNode;
  books: BookReducedInfo[] | null;
  isPending: boolean;
  apiStatus: RequestStatus,
  noBooksMessage: string;
  modifier?: "wishlist";
  onWishChange?: (prop: { nextIsWished: boolean; code: string; origin: RemoteServiceId; }) => void;
  toolbar?: React.ReactNode;
}

const log = l("Books");

export default function Books({
  containerId,
  title,
  books,
  isPending,
  apiStatus,
  noBooksMessage,
  modifier,
  onWishChange,
}: ComponentProps): JSX.Element {

  const { addMessages } = useNotifications();
  const { t } = useTranslation(["common"]);
  const ref = useRef<HTMLHeadingElement>(null);

  const containerLink = useMemo(() => ({
    id: LinkableElementId.booksContent,
    href: `#${containerId}`,
    label: 'Go to content',
    priority: 10,
    actionPriority: 1,
  }), [containerId]);

  const replaceInstruction = useMemo(() => ({
    oldLinkId: LinkableElementId.pageMain,
    newLink: containerLink,
    namespace: LinksDisplayRegion.pageComponent,
  }), [containerLink]);

  useEffect(() => {
    const noBooks = {
      namespace: NotificationNamespace.StatusUpdate,
      id: noBooksMessage,
    };

   if (isPending) {
      log(notification_books_loading);
      addMessages([{
        ...notification_books_loading,
        message: t(notification_books_loading.id),
        priority: NotificationPriority.Queue
      }], [noBooks, notification_books_error, notification_books_loaded]);
    } else if (apiStatus === RequestStatus.SUCCESS && books !== null) {
      if (books.length <= 0) {
        log(noBooks);
        addMessages([{
          ...noBooks,
          message: t(noBooks.id),
          priority: NotificationPriority.Queue
        }], [notification_books_loading, notification_books_error, notification_books_loaded]);
      } else {
        log(notification_books_loaded);
        addMessages([{
          ...notification_books_loaded,
          message: t(notification_books_loaded.id, { count: books.length }),
          priority: NotificationPriority.Queue
        }], [notification_books_loading, notification_books_error, noBooks]);
      }
    } else if (apiStatus === RequestStatus.ERROR) {
      log(notification_books_error);
      addMessages([{
        ...notification_books_error,
        message: t(notification_books_error.id),
        priority: NotificationPriority.Override
      }], [notification_books_loading, noBooks, notification_books_loaded]);
    }
  }, [addMessages, books, isPending, noBooksMessage, apiStatus]);

  useReplaceQuickLink(replaceInstruction, modifier !== "wishlist");

  const location = useLocation();
  const isSearch = location?.pathname?.startsWith('/search');
  useFocusOn(ref, Boolean((location.state?.needsToAskForFocus || isSearch) && apiStatus === RequestStatus.SUCCESS && books));

  return (
    <section
      className="BookGrid__Container"
      id={containerId}
    >
      <h2
        className="BookGrid__Title"
        id={`${containerId}-heading`}
        ref={ref}
        tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
        onFocus={() => log("Focused - books heading")}
      >
        {title}
      </h2>
      {((isPending || (apiStatus === RequestStatus.IDLE && books === null)) && (
        <Loading />
      )) || ((apiStatus === RequestStatus.ERROR && (
        <p className="BookGrid__NoResults">
          {t(notification_books_error.id)}
        </p>
      )) || ((books && books!.length <= 0 && (
        <p className="BookGrid__NoResults">
          {renderMaybeSearchTerm(noBooksMessage)}
        </p>
      )) || (
        <ul
          id={`book-grid-list`}
          className={`BookGrid__List ${modifier ? ` BookGrid__List--${modifier}` : ''}`}
          aria-label={t("Book results")}
          role="region"
        >
          {books!.map((book, index) => {
            return (
              <li
                id={`book-${index}`}
                key={`${book.code}${book.origin}`}
              >
                <BookCard
                  bookListIndex={index}
                  book={book}
                  size={modifier ? "miniature" : undefined}
                  onWishChange={onWishChange ? onWishChange : undefined}
                />
              </li>
            );
          })}
        </ul>
      )))}
    </section>
  );
}
