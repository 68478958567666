import React from 'react';
import { TabIndex } from '../../../types/accessibility';

interface PaginationButtonProps {
  onClick: () => void;
  disabled: boolean;
  label: string;
  withinToolbar?: boolean;
  children: React.ReactNode;
}

export const PaginationButton: React.FC<PaginationButtonProps> = ({
  onClick,
  disabled,
  label,
  withinToolbar = false,
  children
}) => {
  return (
    <button
      className="Pagination__ChangePage"
      onClick={onClick}
      aria-disabled={disabled}
      aria-label={label}
      {...(withinToolbar ? {
        tabIndex: TabIndex.notReachableButProgramaticallyFocusable,
        'data-toolbar-item': 'true'
      } : {})}
    >
      {children}
    </button>
  );
};