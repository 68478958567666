import React, { useContext, useState } from "react";
import { FocusNeededContext } from "./FocusNeededContext";
import { l } from "../../utils/log";

const log = l("FocusNeededProvider");

export const FocusNeededProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [needFocusOnContent, setNeedFocusOnContent] = useState(false);

  log("FOCUS CONTEXT", needFocusOnContent ? "______YEEEES" : "NOOOOO______");

  return (
    <FocusNeededContext.Provider value={{ needFocusOnContent, setNeedFocusOnContent }}>
      {children}
    </FocusNeededContext.Provider>
  );
};

export const useFocusNeeded = () => useContext(FocusNeededContext);