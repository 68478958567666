import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import BookPage from "./pages/Book";
import UserAccountPage from "./pages/UserAccount";
import "./i18n";
import GenresProvider from "./contexts/Genres/GenresProvider";
import UserProvider from "./contexts/User/UserProvider";
import AudioUrlProvider from "./contexts/Audio/AudioUrlProvider";
import ErrorProvider from "./contexts/Error/ErrorProvider";
import BooksProvider from "./contexts/Books/BooksProvider";
import "./theme/variables.scss";
import { useTranslation } from "react-i18next";
import { QuickLinksProvider } from "./contexts/QuickLinks/QuickLinksProvider";
import { NotificationProvider } from "./contexts/Notification/NotificationProvider";

const router = createBrowserRouter([
  {
    path: "/:page?/:format?/:youth?/:sortCriteria?",
    element: (
      <BooksProvider>
        <Home />
      </BooksProvider>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/account/*",
    element: (
      <UserAccountPage />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/book/:bookCode",
    element: (
      <BookPage />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/search/:by/:searchedText/:page/:genre/:format/:youth/:sortCriteria",
    element: (
      <BooksProvider>
        <Home />
      </BooksProvider>
    ),
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const htmlTag = document.documentElement;
    htmlTag.setAttribute("lang", i18n.language);

    const handleLanguageChange = (lng: string) => {
      htmlTag.setAttribute("lang", lng);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  return (
    <ErrorProvider>
      <NotificationProvider>
        <GenresProvider>
          <UserProvider>
            <AudioUrlProvider>
              <QuickLinksProvider>
                <RouterProvider router={router} />
              </QuickLinksProvider>
            </AudioUrlProvider>
          </UserProvider>
        </GenresProvider>
      </NotificationProvider>
    </ErrorProvider>
  );
}

export default App;
