import React, { forwardRef } from 'react';
import './index.scss';

interface TabProps {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  id: string;
  controls: string;
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ children, isActive = false, onClick, id, controls }, ref) => {
    return (
      <button
        ref={ref}
        role="tab"
        id={id}
        aria-selected={isActive}
        aria-controls={controls}
        tabIndex={isActive ? 0 : -1}
        onClick={onClick}
        className={`clickable Tab__Item${isActive ? ' Tab__Item--active' : ''}`}
      >
        {children}
      </button>
    );
  }
);