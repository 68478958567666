import React from 'react';

interface PaginationInfoProps {
  currentPage: number;
  totalPages: number;
  t: (key: string) => string;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  totalPages,
  t
}) => {
  return (
    <div
      className="Pagination__Info"
      aria-label={`${t("Page")} ${currentPage} ${t("of")} ${totalPages}`}
    >
      <p aria-hidden="true">
        {t("Page")}&nbsp;<span className="Pagination__Info--page-current">{currentPage}</span>&nbsp;{t("of")}&nbsp;<span className="Pagination__Info--page">{totalPages}</span>
      </p>
    </div>
  );
};