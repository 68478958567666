import React from 'react';

interface TabPanelProps {
  children: React.ReactNode;
  id: string;
  labelledBy: string;
  isActive: boolean;
}

export function TabPanel({ children, id, labelledBy, isActive }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      id={id}
      aria-labelledby={labelledBy}
      hidden={!isActive}
      tabIndex={0}
    >
      {children}
    </div>
  );
}