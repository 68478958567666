import React, { useState, useEffect, useMemo } from "react";
import { UserContext } from "./UserContext";
import { User } from "../../types/user";
import { hasActiveJwt } from "../../utils/jwt";
import useInterval from "../../hooks/useInterval";
import { useNotifications } from "../Notification/NotificationProvider";
import { notification_auth_disconnected } from "../../utils/notifications";
import { useTranslation } from "react-i18next";
import { NotificationPriority } from "../Notification/NotificationContext";

const userStorageKeyName = "user";

interface GenresProviderProps {
  children: React.ReactNode;
}

export function isUser(user: User | null): user is User {
  return Boolean(user && user?.id && user?.login);
}

const getUserFromStorage = (): User|null => {
    const u = localStorage.getItem(userStorageKeyName);
    if (u === null) {
      return null;
    }
    const user = JSON.parse(u);
    return user;
}

function UserProvider({ children }: GenresProviderProps) {
  // const [needConsultStorage, setNeedConsultStorage] = useState<HasAlteredStorage>(true);
  const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
  const { addMessages } = useNotifications();
  const { t } = useTranslation(["common"]);
  const isJwtActive = hasActiveJwt();
  const [user, setUser] = useState<User|null>(getUserFromStorage());
  const [userSetAt, setUserSetAt] = useState<Date|null>(null);

  useInterval(() => {
    if (!hasActiveJwt()) {
      localStorage.clear();
      setUser(null);
      setUserSetAt(new Date())
    }
  }, user !== null && !transactionInProgress ? 500 : null);

  useEffect(() => {
    if (transactionInProgress) return;
    if (user !== null && !isJwtActive) {
      localStorage.clear()
      setUser(null);
      addMessages([{
        ...notification_auth_disconnected,
        message: t(notification_auth_disconnected.id),
        priority: NotificationPriority.Queue
      }])
    } else if (user === null && isJwtActive) {
      const u = localStorage.getItem(userStorageKeyName);
      if (u === null) {
        localStorage.clear();
        addMessages([{
          ...notification_auth_disconnected,
          message: t(notification_auth_disconnected.id),
          priority: NotificationPriority.Queue
        }])
        return;
      }
      setUser(JSON.parse(u) as User);
    }
    setUserSetAt(new Date);
  }, [isJwtActive]);

  const userContextValue = useMemo(() => ({
    user,
    userSetAt,
    isJwtActive,
    setUser: (nextState: User|null) => {
      if (nextState === null) {
        localStorage.clear();
        addMessages([{
          ...notification_auth_disconnected,
          message: t(notification_auth_disconnected.id),
          priority: NotificationPriority.Queue
        }])
      } else {
        localStorage.setItem(userStorageKeyName, JSON.stringify(nextState));
      }
      setUser(nextState);
      setTransactionInProgress(false);
      setUserSetAt(new Date);
    },
    transactionInProgress,
    setTransactionInProgress,
  }), [
    user,
    userSetAt,
    transactionInProgress,
    isJwtActive,
  ]);

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
