import React, { useRef, useEffect, useState } from 'react';
import Pagination from '../Pagination';
import SearchQueryFilters from '../SearchQueryFilters';
import './index.scss';
import { useTranslation } from 'react-i18next';
import SortingControls from '../SortingControls';
import { l } from '../../utils/log';
import useBookSearch from '../../hooks/useBookSearch';

const log = l("ResultsToolbar");

type ResultsToolbarProps = {
  "aria-controls": string;
};

const ResultsToolbar: React.FC<ResultsToolbarProps> = ({
  "aria-controls": ariaControls,
}) => {
  const { t } = useTranslation(['common']);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarItems, setToolbarItems] = useState<HTMLElement[]>([]);

  const {
    totalCount,
    receivedCount,
    currentBooksValuesParam,
  } = useBookSearch();

  // Collect all focusable items within the toolbar
  useEffect(() => {
    if (toolbarRef.current) {
      const items = toolbarRef.current.querySelectorAll<HTMLElement>(
        '[data-toolbar-item="true"]'
      );
      log("current items", items);
      setToolbarItems(Array.from(items));
    }
  }, [totalCount, receivedCount, currentBooksValuesParam]);

  useEffect(() => {
    const id = setTimeout(() => {
      const firstEnabledItem = toolbarItems.find(
        (item) => item.getAttribute("aria-disabled") !== "true"
      );
      log("current first", firstEnabledItem);
      log("current filtered", toolbarItems.filter(
        (item) => item.getAttribute("aria-disabled") !== "true"
      ));
      if (firstEnabledItem) {
        firstEnabledItem.setAttribute("tabindex", "0");
      }
    }, 700);
    return () => clearTimeout(id);
  }, [toolbarItems]);

  // Handle key navigation within the toolbar
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const currentIndex = toolbarItems.findIndex(
      (item) => item === document.activeElement
    );

    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = (currentIndex + 1) % toolbarItems.length;
      toolbarItems[currentIndex]?.setAttribute("tabindex", "-1");
      toolbarItems[nextIndex]?.setAttribute("tabindex", "0");
      toolbarItems[nextIndex]?.focus();
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex =
        (currentIndex - 1 + toolbarItems.length) % toolbarItems.length;
      toolbarItems[currentIndex]?.setAttribute("tabindex", "-1");
      toolbarItems[prevIndex]?.setAttribute("tabindex", "0");
      toolbarItems[prevIndex]?.focus();
    } else if (event.key === 'Home') {
      event.preventDefault();
      toolbarItems[currentIndex]?.setAttribute("tabindex", "-1");
      toolbarItems[0]?.setAttribute("tabindex", "0");
      toolbarItems[0]?.focus();
    } else if (event.key === 'End') {
      event.preventDefault();
      toolbarItems[currentIndex]?.setAttribute("tabindex", "-1");
      toolbarItems[toolbarItems.length - 1]?.setAttribute("tabindex", "0");
      toolbarItems[toolbarItems.length - 1]?.focus();
    }
  };

  return (
    <div
      className="ResultsToolbar"
      role="toolbar"
      aria-label={`${t('Results controls')}`}
      ref={toolbarRef}
      onKeyDown={handleKeyDown}
      aria-controls={ariaControls}
    >
      <SortingControls />
      <Pagination
        withinToolbar={true}
      />
      <SearchQueryFilters />
    </div>
  );
};

export default ResultsToolbar;
