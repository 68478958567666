// NotificationContext.tsx
import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  NotificationPriority,
  Notification,
  NotificationContext,
  AddNotificationParam,
  NotificationIdentifier,
  TimeToSpeakProp,
  IdProp,
} from './NotificationContext';
import { splitArray } from '../../utils/splitArray';
import { l } from '../../utils/log';

const log = l('NotificationsProvider');

const computeTimeToSpeak = (message: string) => {
  const wordsPerMinute = 160; // average speaking rate for VoiceOver
  const words = message.split(' ').length;
  return (words / wordsPerMinute) * 60 * 1000; // convert to milliseconds
};

const addProps = <T extends AddNotificationParam>(n: T): T & TimeToSpeakProp & IdProp => {
  return {
    ...n,
    timeToSpeak: computeTimeToSpeak(n.message),
    id: n.id || n.message,
  };
};

const isInList = (notif: Notification, notifIds: NotificationIdentifier[]) => -1 !== notifIds.findIndex((n) => notif.namespace === n.namespace && notif.id === n.id)

const remove = (prevQueue: Notification[], toRemove: NotificationIdentifier[]) =>
  prevQueue.filter((notificationInPrevQueue) => !isInList(notificationInPrevQueue, toRemove)
  );

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [queue, setQueue] = useState<Notification[]>([]);
  const [currentMessage, setCurrentMessage] = useState<Notification | null>(null);

  log("QUEUE", queue);
  log("CURR MESSAGE", currentMessage);

  const removeMessages = useCallback((notifications: NotificationIdentifier[]) => {
    setQueue((prevQueue) => remove(prevQueue, notifications));
  }, []);

  const addMessages = useCallback(
    (
      notificationsToAdd: AddNotificationParam[],
      notificationsToRemove?: NotificationIdentifier[]
    ) => {
      const [queued, override] = splitArray(
        notificationsToAdd.map(addProps),
        (n) => n.priority === NotificationPriority.Queue
      );
      log("notifToADD", notificationsToAdd);
      log("queueeed", queued);
      log("override", override);
      setQueue((prevQueue) => {
        log('set queue --- prev', prevQueue);
        if (override.length > 0) {
          log('---- override > 0 ret', override[0]);
          const notificationAlreadyQueuedIndex = prevQueue.findIndex((n) => n.id === override[0].id);
          if (notificationAlreadyQueuedIndex !== -1) {
          notificationAlreadyQueuedIndex !== 0 && log("--------- DO DO 'override' : ", override);
            return notificationAlreadyQueuedIndex === 0
              ?  prevQueue
              : [
                ...prevQueue.splice(notificationAlreadyQueuedIndex, 1),
                ...prevQueue
            ];
          }
          log("--------- DO 'override' : ", override);
          return [override[0]]; // Override the queue
        } else {
          const withoutRemoved = notificationsToRemove
            ? remove(prevQueue, notificationsToRemove)
            : prevQueue;
          log('---- override <= 0 ret', [...withoutRemoved, ...queued]);
          log("--------- 'withoutRemoved' : ", withoutRemoved);
          log("--------- 'NOTIF TO REMOVE' : ", notificationsToRemove);
          return [...withoutRemoved, ...queued]; // Add to the queue
        }
      });
    },
    [setQueue]
  );

  // Process the queue whenever currentMessage is null and the queue has items
  useEffect(() => {
    log('>>>>>>>>>>>>>>>>');
    log('currentMessage', currentMessage, queue);
    if (!currentMessage && queue.length > 0) {
      log('||||||||||||| queue', queue);
      const nextMessage = queue[0];
      setCurrentMessage(nextMessage);
      // should do [, ...nextQueue] but finding index seems safer
      setQueue((prevQueue) => {
        log("setting curr message -- prevQueue", prevQueue);
        const nextQueue = prevQueue.filter(n => n.id !== nextMessage.id)
        log("setting curr message -- nextQueue", nextQueue);
        return nextQueue;
      });
      log('setting current message', nextMessage);
      log('SETTING CURRENT MESSAGE', nextMessage);
    }
    log('<<<<<<<<<<<<<<<<');
  }, [queue, currentMessage]);

  // Use useEffect to handle the timing and removal of the current message
  useEffect(() => {
    if (currentMessage) {
      log('starting timeout for current message', currentMessage);
      log('STARTING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
      const timeoutId = setTimeout(() => {
        log('setting current message to null');
        log('NULL SETTING CURRENT MESSAGE TO NULL');
        setCurrentMessage(null);
        log('after announcement remove from queue');
        log('AFTER ANNOUNCEMENT REMOVE FROM QUEUE');
        setQueue((prevQueue) => {
          log("after remove -- prevQueue", prevQueue);
          const nextQueue = prevQueue.filter(n => n.id !== currentMessage.id)
          log("after remove -- nextQueue", nextQueue);
          return nextQueue;
        });
      }, currentMessage.timeToSpeak);

      // Clean up the timeout if currentMessage changes or the component unmounts
      return () => {
        log('clearing timeout for current message', currentMessage);
        log('CLEARING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
        clearTimeout(timeoutId);
      };
    }
  }, [currentMessage]);

  return (
    <NotificationContext.Provider value={{ addMessages, removeMessages }}>
      <div
        className='visually-hidden'
        aria-live={
          currentMessage?.priority === NotificationPriority.Override ? 'assertive' : 'polite'
        }
        aria-atomic="true"
      >
        {currentMessage?.message}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
